

























































































































































.auth-form {
  min-width: 19.375rem;
  max-width: 350px;
  .card-body {
    overflow: hidden;
    -webkit-box-shadow: inset 0 4px 0 0 #17c671;
    box-shadow: inset 0 4px 0 0 #17c671;
    border-radius: .625rem;
    .btn {
      font-size: 1rem;
    }
  }
  .auth-form__logo {
    max-width: 250px;
  }
  .auth-form__meta a {
    font-weight: 400;
    color: #818ea3;
  }
}
